@import-normalize; /* bring in normalize.css styles */

:root {
  --light100: rgba(255, 255, 255, 1);
  --darkBlack100: rgba(21, 21, 21, 1);
  --dark5: rgba(234, 234, 234, 1);
  --dark70: rgba(89, 89, 89, 1);
  --primaryPrimary: rgba(210, 151, 188, 1);
  --primaryHover: rgba(186, 132, 166, 1);
  --secondaryLightBlue: rgba(211, 236, 254, 1);
  --modalBackgdrop: rgba(0, 0, 0, 0.7);
  --transitionTimingFunction: 400ms cubic-bezier(0.1, 0, 0.2, 1);
}

/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

* {
  box-sizing: border-box;
}

/* Sections
     ========================================================================== */

/**
   * Render the `main` element consistently in IE.
   */
main {
  display: block;
}

footer,
header,
menu,
nav,
section {
  display: block;
}

/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
     ========================================================================== */
/**
   * Remove the gray background & italics on active links in IE 10.
   */
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
a:focus,
a:hover,
a:active,
a:visited {
  outline: none;
}

/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder;
}

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%;
}

/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
     ========================================================================== */
/**
   * Remove the border on images inside links in IE 10.
   */
img {
  border-style: none;
  display: block;
  max-width: 100%;
  height: auto;
}

/* Forms
     ========================================================================== */

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
   * Correct the padding in Firefox.
   */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline;
}

/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto;
}

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type='checkbox'],
[type='radio'] {
  /* 1 */
  padding: 0;
}

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type='search'] {
  outline-offset: -2px;
}

/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
  display: block;
}

/*
   * Add the correct display in all browsers.
   */
summary {
  display: list-item;
}

/* Misc
     ========================================================================== */
/**
   * Add the correct display in IE 10+.
   */
template {
  display: none;
}

/**
   * Add the correct display in IE 10.
   */
[hidden] {
  display: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
textarea,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Poppins;
}

ul {
  list-style: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  font-family: Poppins;
  padding-block: 0px;
  padding-inline: 0px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
  color: rgb(21, 21, 21);

  background-color: #f7f7f7;
}

/*---------- Unified text styles----------- */

h1 {
  font-size: 27px;
  font-weight: 600;
  line-height: 41px;
}

/** AddImgsTitle, CreateTitle */
h2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
}

/* ModalTitle (Create) */
h3 {
  font-size: 27px;
  font-weight: 600;
  line-height: 32px;
}

/* AddImgButton */
h4 {
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
}

/* SUBTITLE/M */
h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* header NavTitle, MenuTitle */
h6 {
  font-size: 16px;
  font-weight: 400;
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium)
    var(--amplify-space-s) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--primaryPrimary);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 0px
    var(--primaryHover);
  --amplify-components-fieldcontrol-focus-border-color: transparent;
  --amplify-components-tabs-item-active-border-color: var(--primaryHover);
  --amplify-components-tabs-item-color: #151515;
  --amplify-components-tabs-item-active-color: var(--primaryHover);
  --amplify-components-button-link-color: var(--dark70);
  --amplify-components-tabs-item-transition-duration: 400ms
    cubic-bezier(0.1, 0, 0.2, 1);

  width: 100vw;
  position: relative;
  display: flex;
  justify-content: flex-end;
  background-image: image-set(
    url('media/images/auth_bg.jpg') 1x,
    url('media/images/auth_bg_2x.jpg') 2x,
    url('media/images/auth_bg_3x.jpg') 3x
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 100vw;
  min-height: 100vh;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: var(--light100);

  @media screen and (min-width: 768px) {
    width: 50vw;
  }

  @media screen and (min-width: 1201px) {
    width: 69vw;
  }
}

[data-amplify-authenticator] [data-amplify-router] {
  max-width: 520px;
  width: 100vw;
  padding-bottom: 24px;
  align-self: center;
  margin: auto 0;
  box-shadow: none;

  @media screen and (min-width: 768px) {
    width: 50vw;
  }

  @media screen and (min-width: 1201px) {
    width: 69vw;
  }
}

.auth-logo {
  width: 100vw;
  max-width: 520px;
  padding-top: 56px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;

  background-color: var(--light100);

  @media screen and (min-width: 768px) {
    width: 50vw;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 1201px) {
    padding-left: 0;
    padding-right: 0;
    align-self: center;
  }
}

.amplify-tabs__panel {
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (min-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 1201px) {
    padding: 0;
  }
}

.amplify-divider {
  margin-top: 16px;
  margin-bottom: 14px;
  border-color: var(--dark5);
}

.amplify-tabs__item:hover {
  color: var(--primaryHover);
}

.amplify-flex {
  gap: 12px;
}

.amplify-field {
  gap: 4px;
}

.amplify-field-group {
  gap: 0;
}

.amplify-label {
  color: var(--darkBlack100);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.amplify-field-group__outer-end {
  --amplify-components-button-focus-box-shadow: none;
  --amplify-internal-button-border-color: transparent;
}

.federated-sign-in-button {
  --amplify-components-button-focus-box-shadow: none;
  border: 1px solid var(--dark5);
  border-radius: 16px;
}

.amplify-field__show-password,
.amplify-field__show-password:hover,
.amplify-field__show-password:active {
  --amplify-components-button-focus-box-shadow: none;
  --amplify-internal-button-border-color: transparent;
  border: 1px solid var(--dark5);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: transparent;

  & > svg {
    fill: var(--darkBlack100);
  }
}

.amplify-input {
  height: 42px;
  padding: 10px 20px;

  color: var(--darkBlack100);
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid var(--dark5);
  outline: none;
  --amplify-components-fieldcontrol-focus-border-color: var(--dark5);

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.amplify-button--primary,
.amplify-button--small {
  padding: 10px 20px;
  border-radius: 16px;
}

.amplify-button--primary {
  margin-top: 16px;

  color: var(--light100);
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
}

.amplify-button--primary:hover {
  background-color: var(--primaryHover);
}

.amplify-button--link {
  color: var(--darkBlack100);
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}

.amplify-button--link:hover {
  background-color: var(--secondaryLightBlue);
}

/*-----auth-advert-part------*/

.auth-welcome-info {
  position: absolute;
  bottom: 40px;
  left: 40px;

  text-align: start;

  @media screen and (min-width: 1201px) {
    bottom: 32px;
    left: 32px;
  }
}

.auth-welcome-text-wrap {
  width: calc(50vw - 80px);
  min-height: 282px;
  padding: 40px 32px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 14px;
  opacity: 0.9;
  background-color: var(--darkBlack100);

  @media screen and (min-width: 768px) {
    display: flex;
  }

  @media screen and (min-width: 1201px) {
    width: calc(31vw - 64px);
  }
}

.auth-welcome-title {
  width: 100%;
  color: var(--light100);
  font-size: 41px;
  font-weight: 600;
  line-height: 47px;
}

.auth-welcome-text {
  color: var(--light100);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.auth-welcome-interaction {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.auth-welcome-btn,
.auth-welcome-link {
  min-width: 156px;
  max-width: 223px;
  width: calc(50% - 10px);
  height: 48px;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 14px;

  color: var(--light100);
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;

  transition: color var(--transitionTimingFunction),
    border-color var(--transitionTimingFunction);
}

.auth-welcome-btn {
  background-color: transparent;
  border: 1px solid var(--light100);
}

.auth-welcome-btn:hover {
  color: var(--primaryPrimary);
  border-color: var(--primaryPrimary);
}

.auth-welcome-link {
  background-color: transparent;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 7.5%;
  text-underline-offset: 25%;
}

.auth-welcome-link:hover {
  color: var(--primaryPrimary);
}

.agree-text {
  color: #595959;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}

.agree-link {
  color: #2e3fd7;
}

.agree-link:hover {
  text-decoration: underline;
}
